import React from 'react';
import CustomTextField from './shared/CustomTextField';
import CustomDropdown from './shared/CustomDropdown';

const DoppleSearch = ({
  doppleSearch,
  setDoppleSearch,
  doppleList,
  handleDoppleClick,
  scrollRef,
  hasScrolled,
  setHasScrolled,
  handleDoppleFetch,
  isDoppleFetch,
  models,
  handleModelSelect,
  fetchDopplePrompt,
  fetchDoppleRules,
  tabValue,
  selectedDoppleDetails,
  rotate
}) => {
  
  const handleDoppleSearchScroll = event => {
    if (!hasScrolled) {
      setHasScrolled(true);
    }

    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollHeight - scrollTop <= clientHeight && !isDoppleFetch) {
      handleDoppleFetch();
    }
  };

  return (
    <div>
      <div className="flex space-x-2 flex-nowrap">
        <CustomTextField
          label="Enter Dopple Name"
          multiline={false}
          value={doppleSearch}
          onChange={(e) => setDoppleSearch(e.target.value)}
          className={"w-[45%]"}
        />
        <CustomDropdown
            fetchedData={models}
            onModelSelect={handleModelSelect}
        />
        {tabValue === "rules" && (
            <button
            disabled={!selectedDoppleDetails}
            className={`shrink-0 flex justify-center items-center rounded-xl  p-2 bg-[#23252B] border border-[#363941] hover:border-white ${
                !selectedDoppleDetails && " opacity-25 cursor-not-allowed"
            }`}
            onClick={() => fetchDoppleRules()}
            >
            <svg
                width={30}
                height={25}
                viewBox={`0 0 ${25} ${25}`}
                fill="#848D97"
                className={`cursor-pointer mr-1.5 fill-active dark:fill-subtext light:fill-subtext stroke-none ${
                rotate ? "animate-rotate-infinite" : ""
                }`}
            >
                <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.876 15.6239C7.467 16.5926 8.3205 17.374 9.33752 17.8773C10.3545 18.3806 11.4934 18.5853 12.622 18.4676C13.7507 18.35 14.8228 17.9148 15.7142 17.2125C16.6055 16.5103 17.2795 15.5697 17.658 14.4999H19.748C19.3645 15.9823 18.5638 17.3235 17.441 18.3644C16.3181 19.4054 14.9202 20.1023 13.4131 20.3727C11.906 20.643 10.3531 20.4754 8.9384 19.8896C7.52374 19.3038 6.30682 18.3246 5.432 17.0679L3 19.4999V13.4999H9L6.876 15.6239ZM17.125 9.37494C16.5337 8.40611 15.6799 7.62472 14.6626 7.1214C13.6452 6.61807 12.5061 6.41342 11.3772 6.53118C10.2483 6.64894 9.17589 7.08428 8.28434 7.78672C7.3928 8.48916 6.71863 9.42993 6.34 10.4999H4.25C4.63336 9.01705 5.4341 7.67526 6.55719 6.63381C7.68028 5.59237 9.07858 4.89498 10.5861 4.62442C12.0937 4.35386 13.6472 4.52148 15.0624 5.10739C16.4775 5.6933 17.6949 6.6729 18.57 7.92994L21 5.49994V11.4999H15L17.125 9.37494Z"
                />
            </svg>
            <span className="">Refresh Rules</span>
            </button>
        )}
        {tabValue === "prompt+shots" && (
            <button
            disabled={!selectedDoppleDetails}
            className={`shrink-0 flex justify-center items-center rounded-xl  p-2 bg-[#23252B] border border-[#363941] hover:border-white ${
                !selectedDoppleDetails && " opacity-25 cursor-not-allowed"
            }`}
            onClick={() => fetchDopplePrompt()}
            >
            <svg
                width={30}
                height={25}
                viewBox={`0 0 ${25} ${25}`}
                fill="#848D97"
                className={`cursor-pointer mr-1.5 fill-active dark:fill-subtext light:fill-subtext stroke-none ${
                rotate ? "animate-rotate-infinite" : ""
                }`}
            >
                <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.876 15.6239C7.467 16.5926 8.3205 17.374 9.33752 17.8773C10.3545 18.3806 11.4934 18.5853 12.622 18.4676C13.7507 18.35 14.8228 17.9148 15.7142 17.2125C16.6055 16.5103 17.2795 15.5697 17.658 14.4999H19.748C19.3645 15.9823 18.5638 17.3235 17.441 18.3644C16.3181 19.4054 14.9202 20.1023 13.4131 20.3727C11.906 20.643 10.3531 20.4754 8.9384 19.8896C7.52374 19.3038 6.30682 18.3246 5.432 17.0679L3 19.4999V13.4999H9L6.876 15.6239ZM17.125 9.37494C16.5337 8.40611 15.6799 7.62472 14.6626 7.1214C13.6452 6.61807 12.5061 6.41342 11.3772 6.53118C10.2483 6.64894 9.17589 7.08428 8.28434 7.78672C7.3928 8.48916 6.71863 9.42993 6.34 10.4999H4.25C4.63336 9.01705 5.4341 7.67526 6.55719 6.63381C7.68028 5.59237 9.07858 4.89498 10.5861 4.62442C12.0937 4.35386 13.6472 4.52148 15.0624 5.10739C16.4775 5.6933 17.6949 6.6729 18.57 7.92994L21 5.49994V11.4999H15L17.125 9.37494Z"
                />
            </svg>
            <span className="">Refresh Dopple Prompt</span>
            </button>
        )}
      </div>
      {doppleSearch && (
        <div
          onScroll={handleDoppleSearchScroll}
          ref={scrollRef}
          className="scrollbar-div overflow-y-auto h-[15rem] bg-[#191A1E] border border-[#363941] w-[20.5%] rounded-b-lg absolute z-30"
        >
        {isDoppleFetch ? (
            <div className="flex justify-center items-center h-full">
              <div className="loader">Loading...</div>
            </div>
          ) : (
            <>
              {doppleList.length === 0 ? (
                <div className="text-white px-4 py-2 text-sm bg-black bg-opacity-0 w-full h-full flex justify-center items-center">
                  No Dopples Found
                </div>
              ) : (
                doppleList.map((dopple, index) => (
                  <button
                    key={index}
                    className="text-white px-4 py-2 text-sm bg-black bg-opacity-0 w-full hover:bg-opacity-10 text-start border-b border-[#363941]"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={() => handleDoppleClick(dopple)}
                    id={`menu-item-${index}`}
                  >
                    <div className="flex items-center space-x-2">
                      <img
                        src={dopple.doppleImageUrl}
                        className="w-11 rounded-full"
                        alt="dopple_avatar"
                      />
                      <p className="text-md font-bold">{dopple.dopple_name}</p>
                    </div>
                  </button>
                ))
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DoppleSearch;
