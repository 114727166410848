import './App.css';
import './tailwind.css';
import PromptEng from './component/PromptEng';

function App() {
  return (
    <div>
      <PromptEng/>
    </div>
  );
}

export default App;
