
const baseUrl = 'https://dopplebrain-dev-kvwjgeyzqa-uc.a.run.app';
const authHeader = { 'Authorization': 'Bearer fda1efa9-8fab-49af-8eca-6afd2818dd64' };

export const getChatID = async (doppleId) => {
    const url = `${baseUrl}/get_prompt_engineering_tool_chat_id?username=PROMPT_ENGINEERING_USER&dopple_id=${doppleId}`;
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const getModels = async () => {
  const url = `${baseUrl}/get_available_models`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json()
  } catch (error) {
    console.error('Error:', error);
  }
};


export const formatChatHistoryForChatLog = (chatHistory) => {
    return chatHistory.map((entry) => {
      // Determine if the entry is from an AI or a user
      const isAI = entry.message.type === 'ai';
      // Format the message content based on the type
      const messageContent = entry.message.data.content;
  
      // Construct the new entry for chatLog format
      return {
        speaker: isAI ? 'AI' : 'user',
        message: messageContent,
        // Add isLoading property for AI messages; assume false for simplicity
        ...(isAI && { isLoading: false }),
      };
    });
  };  

export const getChatHistory = async (doppleId, chatID) => {
    const url = `${baseUrl}/get_paginated_chat_history`;
    const requestBody = {
        username: 'PROMPT_ENGINEERING_USER',
        dopple_id: doppleId,
        chat_id: chatID,
        folder: '',
        skip: 0,
        limit: 9999999,
    };
    const response = await fetch(url, {
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const getDoppleRules = async (doppleId) => {
    const url = `${baseUrl}/get_dopple_rules?dopple_id=${doppleId}`;
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const getDopplePrompt = async (doppleId) => {
    const url = `${baseUrl}/get_dopple_prompt?dopple_id=${doppleId}`;
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const getDoppleShots = async (doppleId) => {
    const url = `${baseUrl}/get_dopple_shots?dopple_id=${doppleId}`;
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const getAIResponse = async (requestBody) => {
    const url = `${baseUrl}/get_ai_response_from_components`;
    const response = await fetch(url, {
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const getDoppleList = async (searchTerm, skip, limit) => {
    const url = `https://us-central1-influencer-bot-386816.cloudfunctions.net/dopple_reporting/search_dopples`;
    const requestBody = {
        search_term: searchTerm,
        skip,
        limit,
    };
    const response = await fetch(url, {
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};
