import React from 'react';
import CustomTextField from './shared/CustomTextField';
import CustomTable from './shared/CustomTable';

const PersonaSection = ({ persona, setPersona, memories }) => (
  <div className="flex flex-col flex-grow pt-2 justify-between">
    <CustomTextField
      label="Persona Text Box"
      multiline
      value={persona}
      onChange={(e) => setPersona(e.target.value)}
      rows={6}
    />
    {memories.length > 0 && <CustomTable memories={memories} />}
  </div>
);

export default PersonaSection;
