import React from 'react';
import CustomTextField from './shared/CustomTextField';

const RulesSection = ({ topRules, setTopRules, midRules, setMidRules, bottomRules, setBottomRules }) => (
  <div className="flex flex-col space-y-4 flex-grow overflow-auto pt-2 scrollbar-div">
    <CustomTextField
      label="Top Rules Text Box"
      multiline
      value={topRules}
      onChange={(e) => setTopRules(e.target.value)}
      rows={6}
      className={"flex-grow"}
    />
    <CustomTextField
      label="Middle Rules Text Box"
      multiline
      value={midRules}
      onChange={(e) => setMidRules(e.target.value)}
      rows={6}
      className={"flex-grow"}
    />
    <CustomTextField
      label="Bottom Rules Text Box"
      multiline
      value={bottomRules}
      onChange={(e) => setBottomRules(e.target.value)}
      rows={6}
      className={"flex-grow"}
    />
  </div>
);

export default RulesSection;
