import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CustomDropdownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  color: '#848D97',
}));

const CustomDropdown = ({ fetchedData, onModelSelect, width }) => {
  const [selectedModel, setSelectedModel] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad && fetchedData.length > 0) {
      const defaultModel = fetchedData[0];
      setSelectedModel(defaultModel);
      onModelSelect(defaultModel);
      setFirstLoad(false);
    }
  }, [fetchedData, firstLoad, onModelSelect]);

  const handleSelectModel = (event) => {
    setSelectedModel(event.target.value);
    onModelSelect(event.target.value);
  };

  const modelsArray = fetchedData;

  return (
    <Box
      sx={{
        width: width ? width : 250,
        "& .MuiInputLabel-root": {
          color: "#6A7179", // Label color
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "white", // Label color when the input is focused
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderRadius: "16px",
          borderColor: "#363941", // Border color
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "white", // Border color on hover
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "white", // Border color when focused
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "white", // Border color on hover and when focused
        },
        "& .MuiInputBase-input": {
          color: "white", // Text color
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#6A7179", // Placeholder text color
        },
        "& .MuiInputBase-inputMultiline": {
          "&::-webkit-scrollbar": {
            width: "10px",
            height: "10px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#363941",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#8598b4",
          },
        },
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="models">Models</InputLabel>
        <Select
          labelId="selectModels"
          id="selectModels"
          value={selectedModel}
          label="Models"
          onChange={handleSelectModel}
          IconComponent={CustomDropdownIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              width: 'auto',
              "& .MuiPaper-root": {
                backgroundColor: "#191A1E",
                borderColor: "#363941",
                borderWidth: "1px",
                borderStyle: "solid",
              },
            },
          }}
        >
          {Array.isArray(modelsArray) &&
            modelsArray.map((model, index) => (
              <MenuItem
                key={index}
                value={model}
                sx={{
                  color: 'white'
                }}
              >
                {model}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomDropdown;
