import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import brain from '../../images/brain-engine-svgrepo-com.svg';

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#191A1E", // Example primary color
    },
    background: {
      default: "#191A1E", // Example background color
      paper: "#191A1E",
    },
    text: {
      primary: "#ffffff",
      secondary: "rgba(255, 255, 255, 0.7)",
    },
  },
  components: {
    // Name of the component
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "16px",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          // This targets the table header cells
          fontWeight: "bold", // Makes the header text bold
        },
        root: {
          // Some CSS
          color: "white", // You can adjust this as needed
          backgroundColor: "#191A1E",
          borderBottom: "1px solid #363941",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "#191A1E",
        },
      },
    },
  },
});

const columns = [{ id: "memories", label: "Memories", minWidth: 170 }];

function createData(memories) {
  return { memories };
}

export default function CustomTable({ memories }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = memories.map(memory => createData(memory));

  React.useEffect(() => {
    console.log(memories)
  }, [memories])

  return (
    <ThemeProvider theme={darkTheme}>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          border: 1,
          borderColor: "#363941",
        }}
      >
        <TableContainer sx={{ maxHeight: 440, minHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <span className="flex"><img className="pr-2" src={brain} alt="AI Brain Chip"/> {column.label}</span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.memories}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id + value} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </ThemeProvider>
  );
}
