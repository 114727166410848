import React from 'react';
import CustomTextField from './shared/CustomTextField';
import { DeleteTwoTone, SendOutlined, CloseOutlined, CheckOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import blankProfile from '../images/blank-profile.svg';
import Checkbox from '@mui/material/Checkbox';
import { CheckCircleOutlined } from '@ant-design/icons';

const ChatSection = ({
  selectedDoppleDetails,
  handleClearChat,
  chatLog,
  handleReRoll,
  userQueryPrompt,
  setUserQueryPrompt,
  handleChange,
  handleEditMessageX,
  handleUpdateMessage,
  handleUserMessage,
  isEditMessage,
  setIsEditMessage,
  isChangingMessage,
  setIsChangingMessage,
  editingMessageIndex,
  setEditingMessageIndex,
  rotate,
  rerolledMessages,
  currentRerollIndex,
  goToNextMessage,
  goToPreviousMessage,
  lastIndexUserMessage,
  deleteMessagesBubbles,
  setDeleteMessagesBubbles,
  checkedMessages,
  handleCheckboxChange,
  messageBoxRef,
  startEditMessage,
  handleDeleteLastMessagePair,
  handleCancelClick,
}) => {
  const processMessages = (text) => {
    if (typeof text !== 'string') {
      console.error('processMessages received non-string input', text);
      return null;
    }
    const regex = /(\*[^*]+\*)/g;
    const splitText = text.split(regex);
    return splitText.flatMap((part, index) => {
      if (part.startsWith('*') && part.endsWith('*')) {
        return <span key={index} style={{ fontStyle: 'italic', color: 'grey' }}>{part.slice(1, -1)}</span>;
      } else {
        return part.split('\n').map((line, lineIndex) => (
          <React.Fragment key={`fragment-${index}-${lineIndex}`}>
            {line}
            {lineIndex < part.split('\n').length - 1 && <br />} 
          </React.Fragment>
        ));
      }
    });
  };

  return (
    <div className="w-1/2 h-[96%]">
      <div className="bg-[#191A1E] rounded-2xl w-full h-full p-4 flex flex-col flex-grow">
        <div className="flex justify-between">
          {selectedDoppleDetails && (
            <div className="flex items-center space-x-3">
              <img
                className="w-11 rounded-full"
                alt="dopple_avatar"
                src={selectedDoppleDetails.doppleImageUrl}
              />
              <p className="text-2xl font-bold">
                {selectedDoppleDetails.dopple_name}
              </p>
            </div>
          )}
          {selectedDoppleDetails && (
            <button
              className="shrink-0 flex justify-center items-center rounded-xl  p-2 bg-[#23252B] border border-[#363941] hover:border-white"
              onClick={() => handleClearChat()}
            >
              <svg
                width={30}
                height={25}
                viewBox={`0 0 ${25} ${25}`}
                fill="#848D97"
                className={`cursor-pointer mr-1.5 fill-active dark:fill-subtext light:fill-subtext stroke-none ${
                  rotate ? "animate-rotate-infinite" : ""
                }`}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.876 15.6239C7.467 16.5926 8.3205 17.374 9.33752 17.8773C10.3545 18.3806 11.4934 18.5853 12.622 18.4676C13.7507 18.35 14.8228 17.9148 15.7142 17.2125C16.6055 16.5103 17.2795 15.5697 17.658 14.4999H19.748C19.3645 15.9823 18.5638 17.3235 17.441 18.3644C16.3181 19.4054 14.9202 20.1023 13.4131 20.3727C11.906 20.643 10.3531 20.4754 8.9384 19.8896C7.52374 19.3038 6.30682 18.3246 5.432 17.0679L3 19.4999V13.4999H9L6.876 15.6239ZM17.125 9.37494C16.5337 8.40611 15.6799 7.62472 14.6626 7.1214C13.6452 6.61807 12.5061 6.41342 11.3772 6.53118C10.2483 6.64894 9.17589 7.08428 8.28434 7.78672C7.3928 8.48916 6.71863 9.42993 6.34 10.4999H4.25C4.63336 9.01705 5.4341 7.67526 6.55719 6.63381C7.68028 5.59237 9.07858 4.89498 10.5861 4.62442C12.0937 4.35386 13.6472 4.52148 15.0624 5.10739C16.4775 5.6933 17.6949 6.6729 18.57 7.92994L21 5.49994V11.4999H15L17.125 9.37494Z"
                />
              </svg>
              <span className="">Clear Chat</span>
            </button>
          )}
        </div>
        <div className={`flex flex-col flex-grow space-y-4 justify-between overflow-auto`}>
          <div ref={messageBoxRef} className="overflow-y-auto flex-grow pr-1 mt-3 scrollbar-div">
            {chatLog.map((conv, index) => (
              <div key={index} className="space-y-2 pb-2">
                <div className="flex space-x-2">
                  <div className="flex space-x-2 items-center">
                    {deleteMessagesBubbles && (
                      <Checkbox
                        icon={<CheckCircleOutlined style={{ color: '#fff' }}/>}
                        checkedIcon={<CheckCircleOutlined style={{ color: '#2AFF00' }} />}
                        checked={checkedMessages[index] || false}
                        onChange={(e) => handleCheckboxChange(e.target.checked, index)}
                      />
                    )}
                    {conv.speaker === "AI" && selectedDoppleDetails ? (
                      <img
                        className="w-11 h-max rounded-full"
                        alt="dopple_avatar"
                        src={selectedDoppleDetails.doppleImageUrl}
                      />
                    ) : (
                      <img
                        className=" rounded-full"
                        alt="user_default_avatar"
                        src={blankProfile}
                      />
                    )}
                    {conv.isLoading ? (
                      <div className="bg-[#23252B] border border-[#363941] w-min text-white h-[42px] p-2 rounded-xl rounded-bl-none flex items-center">
                        <div className="typing-dot"></div>
                        <div className="typing-dot"></div>
                        <div className="typing-dot"></div>
                      </div>
                    ) : (
                      <p
                        className={`${
                          conv.speaker === "AI" ? "bg-[#23252B]" : "bg-[#1796FF]"
                        } border border-[#363941] text-white h-full p-2 rounded-xl rounded-bl-none flex items-center`}
                      >
                        <span className="">
                          {
                            processMessages(
                              conv.speaker === "AI" &&
                                rerolledMessages.length > 0 &&
                                index === chatLog.length - 1
                                ? rerolledMessages[currentRerollIndex]
                                : conv.message
                            )
                          }
                        </span>
                      </p>
                    )}
                  </div>
                  {conv.speaker === "AI" && (index === chatLog.length - 1) && index !== 0 && !conv.isLoading && (
                    <button className="flex justify-center items-center" onClick={handleReRoll}>
                      <svg
                        width={30}
                        height={25}
                        viewBox={`0 0 ${25} ${25}`}
                        fill="#848D97"
                        className={`cursor-pointer mr-1.5 fill-active dark:fill-subtext light:fill-subtext stroke-none ${
                          rotate ? "animate-rotate-infinite" : ""
                        }`}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.876 15.6239C7.467 16.5926 8.3205 17.374 9.33752 17.8773C10.3545 18.3806 11.4934 18.5853 12.622 18.4676C13.7507 18.35 14.8228 17.9148 15.7142 17.2125C16.6055 16.5103 17.2795 15.5697 17.658 14.4999H19.748C19.3645 15.9823 18.5638 17.3235 17.441 18.3644C16.3181 19.4054 14.9202 20.1023 13.4131 20.3727C11.906 20.643 10.3531 20.4754 8.9384 19.8896C7.52374 19.3038 6.30682 18.3246 5.432 17.0679L3 19.4999V13.4999H9L6.876 15.6239ZM17.125 9.37494C16.5337 8.40611 15.6799 7.62472 14.6626 7.1214C13.6452 6.61807 12.5061 6.41342 11.3772 6.53118C10.2483 6.64894 9.17589 7.08428 8.28434 7.78672C7.3928 8.48916 6.71863 9.42993 6.34 10.4999H4.25C4.63336 9.01705 5.4341 7.67526 6.55719 6.63381C7.68028 5.59237 9.07858 4.89498 10.5861 4.62442C12.0937 4.35386 13.6472 4.52148 15.0624 5.10739C16.4775 5.6933 17.6949 6.6729 18.57 7.92994L21 5.49994V11.4999H15L17.125 9.37494Z"
                        />
                      </svg>
                    </button>
                  )}
                  {conv.speaker === "user" && (index === lastIndexUserMessage) && (
                    <div className="flex space-x-2">
                      <button
                        className="bg-[#23252B] rounded-xl flex items-center justify-center"
                        onClick={() => startEditMessage(index)}
                      >
                        <svg
                          width={30}
                          height={25}
                          viewBox={`0 0 ${23} ${23}`}
                          fill="#848D97"
                          className="cursor-pointer ml-1.5 mr-1 fill-active dark:fill-subtext light:fill-subtext stroke-none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.30892 17.5229H15.9756C16.1966 17.5229 16.4086 17.6107 16.5648 17.767C16.7211 17.9233 16.8089 18.1352 16.8089 18.3563C16.8089 18.5773 16.7211 18.7892 16.5648 18.9455C16.4086 19.1018 16.1966 19.1896 15.9756 19.1896H4.30892C4.08791 19.1896 3.87594 19.1018 3.71966 18.9455C3.56338 18.7892 3.47559 18.5773 3.47559 18.3563C3.47559 18.1352 3.56338 17.9233 3.71966 17.767C3.87594 17.6107 4.08791 17.5229 4.30892 17.5229ZM3.47559 13.3562L11.8089 5.02274L14.3089 7.52278L5.97559 15.8562H3.47559V13.3562ZM12.6423 4.1894L14.3089 2.52271L16.8089 5.02274L15.1414 6.69026L12.6423 4.1894Z"
                          />
                        </svg>
                      </button>
                      <button
                        className="bg-[#23252B] p-3 flex items-center justify-center rounded-xl"
                        onClick={deleteMessagesBubbles ? () => handleDeleteLastMessagePair() : () => setDeleteMessagesBubbles(true)}
                      >
                        {deleteMessagesBubbles ? 'DELETE' : <DeleteTwoTone twoToneColor={"#FF0000"} />}
                      </button>
                      {deleteMessagesBubbles && (
                        <button
                          className="bg-[#23252B] p-3 flex items-center justify-center rounded-xl"
                          onClick={() => handleCancelClick()}
                        >
                          CANCEL
                        </button>
                      )}
                    </div>
                  )}
                </div>
                {rerolledMessages.length > 0 && conv.speaker === "AI" && (index === chatLog.length - 2 || index === chatLog.length - 1) && index !== 0 && !conv.isLoading && (
                  <div className="flex justify-center items-center space-x-1 text-[#848D97] font-bold">
                    <button onClick={goToPreviousMessage} className="flex items-center">
                      <LeftOutlined />
                    </button>
                    <p>{currentRerollIndex + 1} / {rerolledMessages.length}</p>
                    <button onClick={goToNextMessage} className="flex items-center">
                      <RightOutlined />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex flex-col space-y-2">
            {isEditMessage && (
              <div className="w-full rounded-t-xl h-16 flex justify-between space-x-2">
                <div className="flex border border-[#363941] w-full rounded-xl ">
                  <button className="rounded-xl flex items-center justify-center">
                    <svg
                      width={45}
                      height={40}
                      viewBox={`0 0 ${23} ${23}`}
                      fill="#1796FF"
                      className="cursor-pointer ml-1.5 mr-1 fill-active dark:fill-subtext light:fill-subtext stroke-none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.30892 17.5229H15.9756C16.1966 17.5229 16.4086 17.6107 16.5648 17.767C16.7211 17.9233 16.8089 18.1352 16.8089 18.3563C16.8089 18.5773 16.7211 18.7892 16.5648 18.9455C16.4086 19.1018 16.1966 19.1896 15.9756 19.1896H4.30892C4.08791 19.1896 3.87594 19.1018 3.71966 18.9455C3.56338 18.7892 3.47559 18.5773 3.47559 18.3563C3.47559 18.1352 3.56338 17.9233 3.71966 17.767C3.87594 17.6107 4.08791 17.5229 4.30892 17.5229ZM3.47559 13.3562L11.8089 5.02274L14.3089 7.52278L5.97559 15.8562H3.47559V13.3562ZM12.6423 4.1894L14.3089 2.52271L16.8089 5.02274L15.1414 6.69026L12.6423 4.1894Z"
                      />
                    </svg>
                  </button>
                  <div className="border border-[#1796FF] mr-2 h-[80%] flex mt-[0.4rem]"></div>
                  <div className="flex flex-col space-y-1">
                    <p className="text-[#1796FF] font-bold">Edit Message</p>
                    <p className="flex items-center">
                      {isChangingMessage ? userQueryPrompt : chatLog[editingMessageIndex]?.message}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <button className="px-3 h-[80%] bg-[#23252B] rounded-xl text-[#1796FF] flex items-center" onClick={handleEditMessageX}>
                    <CloseOutlined />
                  </button>
                </div>
              </div>
            )}
            <div className="flex justify-between space-x-2">
              <CustomTextField
                label="Enter User Message"
                multiline={false}
                value={userQueryPrompt}
                forceLabelShrink={isEditMessage}
                onChange={handleChange}
                className={"w-full"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (isEditMessage) {
                      handleUpdateMessage();
                    } else {
                      handleUserMessage();
                    }
                  }
                }}
              />
              <button disabled={!selectedDoppleDetails} onClick={isEditMessage ? handleUpdateMessage : handleUserMessage} className={`bg-[#048DFF] text-white p-3 flex items-center justify-center rounded-xl border border-transparent hover:border-white ${!selectedDoppleDetails && 'cursor-not-allowed opacity-50'}`}>
                {isEditMessage ? <CheckOutlined /> : <SendOutlined />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSection;
