import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default function CustomTabs({value, onChange}) {

  return (
    <Box >
      <Tabs
        value={value}
        onChange={onChange}
        textColor="inherit"
        aria-label="tabs label"
        sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#ffffff',
            },
          }}
      >
        <Tab value="persona" label="Persona" sx={{ color: value === 'persona' ? '#ffffff' : '#6A7179' }}/>
        <Tab value="rules" label="Rules" sx={{ color: value === 'rules' ? '#ffffff' : '#6A7179' }}/>
        <Tab value="prompt+shots" label="Prompt + Shots" sx={{ color: value !== 'prompt+shots' ? '#6A7179' : '#ffffff' }}/>
      </Tabs>
    </Box>
  );
}