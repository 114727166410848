import React, {useState} from 'react';
import TextField from '@mui/material/TextField';

const CustomTextField = ({ label, multiline=true, rows, forceLabelShrink, className, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const hasValue = props.value !== null && props.value !== '';

  return (
    <TextField 
      label={label} 
      variant="outlined" 
      multiline={multiline}
      rows={multiline ? rows : 1}

      InputLabelProps={{
        shrink: forceLabelShrink || isFocused || hasValue, // Use the new prop here
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      InputProps={{
        style: { height: '100%' },
      }}
      {...props}
      className={className}
      sx={{
        '& .MuiInputLabel-root': { 
          color: '#6A7179' // Label color
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'white', // Label color when the input is focused
          },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderRadius: '16px', // Roundedness
          borderColor: '#363941', // Border color
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'white', // Border color on hover
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'white', // Border color when focused
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // Border color on hover and when focused
          },
        '& .MuiInputBase-input': { 
          color: 'white', // Text color
          padding: '8px', // Padding, adjust as needed
          height: multiline ? '100%' : '40px'
        },
        '& .MuiInputBase-input::placeholder': { 
          color: '#6A7179' // Placeholder text color
        },
        '& .MuiInputBase-inputMultiline': {
            alignItems: 'flex-start',
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px'
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#363941',
                borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#8598b4',
            },
        },
      }}
    />
  );
};

export default CustomTextField;
