import React, { useCallback, useEffect } from 'react';
import CustomTextField from './shared/CustomTextField';
import { CloseOutlined } from "@ant-design/icons";

const PromptShotsSection = ({ characterPrompt, setCharacterPrompt, shots, setShots, selectedDoppleDetails, blankProfile, textareaRef, shotsBoxRef }) => {
  const handleShotContentChange = (event, index) => {
    const newShots = [...shots];
    newShots[index].content = event.target.value;
    setShots(newShots);
    autoResizeTextarea(index);
  };

  const autoResizeTextarea = useCallback((index) => {
    const element = textareaRef.current[index];
    if (element) {
      element.style.height = "auto"; // Reset height to allow for a potential reduction in content
      element.style.height = element.scrollHeight + "px";
    }
  }, [textareaRef]);

  const handleDeleteShot = (index) => {
    setShots((prevShots) => {
      if (prevShots.length > 4) {
        let newShots = [...prevShots];
        if (newShots[index].type !== "char" && index + 1 < newShots.length) {
          newShots.splice(index, 2);
        } else if (newShots[index].type === "char" && index - 1 >= 0) {
          newShots.splice(index - 1, 2);
        }
        return newShots;
      }
      return prevShots;
    });
  };

  const handleAddShot = () => {
    setShots((prevShots) => [...prevShots, { type: 'user', content: '' }, { type: 'char', content: '' }]);
  };

  useEffect(() => {
    if (shots.length > 0) {
      shots.forEach((_, index) => {
        autoResizeTextarea(index);
      });
    }
  }, [shots, autoResizeTextarea]);

  return (
    <div className="flex flex-col flex-grow overflow-auto space-y-2 pt-2">
      <CustomTextField
        label="Character Prompt Text Box"
        multiline
        value={characterPrompt}
        onChange={(e) => setCharacterPrompt(e.target.value)}
        rows={6}
      />
      <div ref={shotsBoxRef} className="overflow-y-auto flex-grow pr-2 scrollbar-div my-2">
        {shots.map((shot, index) => (
          <div key={index} className="space-y-2 pb-2">
            <div className="flex space-x-2">
              <div className="flex space-x-2 items-center w-full">
                {shot.type === "char" && selectedDoppleDetails ? (
                  <img
                    className="w-11 h-max rounded-full"
                    alt="dopple_avatar"
                    src={selectedDoppleDetails.doppleImageUrl}
                  />
                ) : (
                  <img
                    className="rounded-full"
                    alt="user_default_avatar"
                    src={blankProfile}
                  />
                )}
                {shot && (
                  <div className="flex w-full space-x-2 items-center">
                    <textarea
                      ref={(el) => (textareaRef.current[index] = el)}
                      value={shot.content}
                      onChange={(e) => handleShotContentChange(e, index)}
                      onInput={() => autoResizeTextarea(index)}
                      rows={shot.content.split("\n").length || 1}
                      className={`${
                        shot.type === "char" ? "bg-[#23252B]" : "bg-[#1796FF]"
                      } scrollbar-div border resize-none border-[#363941] text-white w-full p-2 rounded-xl rounded-bl-none overflow-y-hidden`}
                    />
                    {index > 3 && (
                      <button
                        className="bg-[#23252B] p-3 h-full rounded-xl flex items-center justify-center"
                        onClick={() => handleDeleteShot(index)}
                      >
                        <CloseOutlined />
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {shots.length > 0 && (
        <button
          onClick={handleAddShot}
          className={`bg-[#048DFF] w-1/4  text-white p-3 flex items-center justify-center rounded-xl border border-transparent hover:border-white `}
        >
          Add New Message
        </button>
      )}
    </div>
  );
};

export default PromptShotsSection;
