import React, { useCallback, useEffect, useState, useRef } from "react";
import Alert from '@mui/material/Alert';
import CustomTabs from './shared/CustomTabs';
import blankProfile from '../images/blank-profile.svg';
import { getChatID, getChatHistory, getDoppleRules, getDopplePrompt, getDoppleShots, getAIResponse, getDoppleList, formatChatHistoryForChatLog, getModels } from '../utils/fetchUtils';
import DoppleSearch from './DoppleSearch';
import RulesSection from './RulesSection';
import PromptShotsSection from './PromptShotsSection';
import ChatSection from './ChatSection';
import PersonaSection from './PersonaSection';
import FileMenu from "./shared/FileMenu";

const PromptEng = () => {
  const [topRules, setTopRules] = useState('');
  const [midRules, setMidRules] = useState('');
  const [bottomRules, setBottomRules] = useState('');
  const [characterPrompt, setCharacterPrompt] = useState('');
  const [shots, setShots] = useState([]);
  const [userQueryPrompt, setUserQueryPrompt] = useState('');
  const [lastUserQuery, setLastUserQuery] = useState('');
  const [rotate, setRotate] = useState(false);
  const [doppleRulesFirstLoad, setDoppleRulesFirstLoad] = useState(true);
  const [chatLog, setChatLog] = useState([]);
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [isChangingMessage, setIsChangingMessage] = useState(false);
  const [editingMessageIndex, setEditingMessageIndex] = useState(null);
  const [doppleSearch, setDoppleSearch] = useState('');
  const [doppleList, setDoppleList] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const scrollRef = useRef(null);
  const [selectedDoppleDetails, setSelectedDoppleDetails] = useState(null);
  const [dataUpload, setDataUpload] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const messageBoxRef = useRef(null);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [tabValue, setTabValue] = useState('persona');
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const textareaRef = useRef([]);
  const shotsBoxRef = useRef(null);
  const [persona, setPersona] = useState('');
  const [chatID, setChatID] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [memories, setMemories] = useState([]);
  const [rerolledMessages, setRerolledMessages] = useState([]);
  const [currentRerollIndex, setCurrentRerollIndex] = useState(0);
  const [deleteMessagesBubbles, setDeleteMessagesBubbles] = useState(false);
  const [checkedMessages, setCheckedMessages] = useState([]);
  const [lastIndexUserMessage, setLastIndexUserMessage] = useState();
  const [isDoppleFetch, setIsDoppleFetch] = useState(false);

  const startEditMessage = (index) => {
    setEditingMessageIndex(index);
    setUserQueryPrompt(chatLog[index].message);
    setIsEditMessage(true);
  };

  const handleDoppleFetch = useCallback(async () => {
    if (isDoppleFetch) return;

    setIsDoppleFetch(true);
    try {
      const data = await getDoppleList(doppleSearch, doppleList.length, 10);
      const doppleINFO = data.dopples.map(dict => ({
        doppleImageUrl: dict.avatar_url,
        dopple_id: dict.id,
        dopple_username: dict.dopple_username,
        dopple_name: dict.display_name,
        dopple_greeting: dict.greeting
      }));
      setDoppleList(prevDopples => [...prevDopples, ...doppleINFO]);
    } catch (error) {
      console.error('Error fetching dopple list:', error);
    } finally {
      setIsDoppleFetch(false);
    }
  }, [doppleSearch, doppleList.length, isDoppleFetch]);


  const fetchChatID = useCallback(async () => {
    if (selectedDoppleDetails) {
      const data = await getChatID(selectedDoppleDetails.dopple_id);
      setChatID(data.chat_id);
    }
  }, [selectedDoppleDetails]);

  const fetchChatHistory = useCallback(async () => {
    if (selectedDoppleDetails && chatID !== null) {
      const data = await getChatHistory(selectedDoppleDetails.dopple_id, chatID);
      setChatHistory(data.paginated_chat_history.slice().reverse());
      const formattedChatLog = data.paginated_chat_history.slice().reverse().map(item => {
        const speaker = item.message.type === "human" ? "user" : "AI";
        return {
          isLoading: false,
          speaker: speaker,
          message: item.message.data.content
        };
      });
      setChatLog(formattedChatLog);
    }
  }, [selectedDoppleDetails, chatID]);

  const fetchModels = useCallback(async () => {
    const data = await getModels();
    setModels(data.available_models);
  }, [])

  const fetchDoppleRules = useCallback(async () => {
    if (!doppleRulesFirstLoad) {
      setRotate(true);
      setTimeout(() => setRotate(false), 1000);
    }
    setDoppleRulesFirstLoad(false);
    const data = await getDoppleRules(selectedDoppleDetails.dopple_id);
    setTopRules(data.rules.top_rules);
    setMidRules(data.rules.mid_rules);
    setBottomRules(data.rules.bottom_rules);
  }, [doppleRulesFirstLoad, selectedDoppleDetails]);

  const fetchDopplePrompt = useCallback(async () => {
    const data = await getDopplePrompt(selectedDoppleDetails.dopple_id);
    setCharacterPrompt(data.dopple_prompt);
  }, [selectedDoppleDetails]);

  const fetchDoppleShots = useCallback(async () => {
    const data = await getDoppleShots(selectedDoppleDetails.dopple_id);
    setShots(data.dopple_shots);
  }, [selectedDoppleDetails]);

  const handleModelSelect = (model) => {
    setSelectedModel(model);
  };

  const fetchAIResponse = async (query = userQueryPrompt, isEdit = false) => {
    if (query !== '') {
      setLastUserQuery(query);
    }
    if (isEdit) {
      setUserQueryPrompt('');
    }
    if (rerolledMessages.length > 0) {
      const commitRequestBody = {
        username: 'PROMPT_ENGINEERING_USER',
        dopple_id: selectedDoppleDetails.dopple_id,
        chat_id: chatID,
        ai_response: rerolledMessages[currentRerollIndex],
        folder: '',
      };
      const commitURL = `https://dopplebrain-dev-kvwjgeyzqa-uc.a.run.app/commit_rerolled_response`;
      try {
        const response = await fetch(commitURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer fda1efa9-8fab-49af-8eca-6afd2818dd64'
          },
          body: JSON.stringify(commitRequestBody),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responseData = await response.json();
        console.log(responseData);
      } catch (error) {
        console.error('Error:', error);
      }
      setRerolledMessages([]);
    }

    let newChatLogEntry;

    if (!isEdit) {
      const userMessage = {
        message: {
          type: "user",
          data: {
            content: query,
            example: false
          }
        },
        timestamp: new Date().getTime()
      };
      setChatHistory(prevHistory => [...prevHistory, userMessage]);
      newChatLogEntry = { speaker: "AI", isLoading: true };
      setChatLog(prevChatLog => [...prevChatLog, newChatLogEntry]);
    } else {
      setRotate(true);
    }

    const requestBody = {
      username: "PROMPT_ENGINEERING_USER",
      prompt: characterPrompt,
      user_query: query,
      dopple_id: selectedDoppleDetails.dopple_id,
      rules: { 'top_rules': topRules, 'mid_rules': midRules, 'bottom_rules': bottomRules },
      chat_history: chatHistory,
      request_type: isEdit ? 'edit' : userQueryPrompt ? 'new' : 'continue',
      model_id: selectedModel,
      shots: shots ? shots : '',
      persona_info : persona,
      chat_id: chatID,
    };
    const data = await getAIResponse(requestBody);

    if (isEdit) {
      const lastAIIndex = chatLog.reduce((acc, curr, idx) => curr.speaker === "AI" ? idx : acc, -1);

      if (lastAIIndex !== -1) {
        setChatLog(prevChatLog => {
          let newChatLog = [...prevChatLog];
          newChatLog[lastAIIndex] = { speaker: "AI", message: data.ai_response, isLoading: false };
          return newChatLog;
        });
        
        const lastAIHistoryIndex = chatHistory.reduce((acc, curr, idx) => curr.message.type === "ai" ? idx : acc, -1);
        if (lastAIHistoryIndex !== -1) {
          setChatHistory(prevHistory => {
            let updatedHistory = [...prevHistory];
            updatedHistory[lastAIHistoryIndex] = {
              message: {
                type: "ai",
                data: { content: data.ai_response, example: false },
              },
              timestamp: new Date().getTime()
            };
            return updatedHistory;
          });
        }
      }
      setRotate(false);
    } else {
      setChatLog(prevChatLog => {
        let newChatLog = [...prevChatLog];
          newChatLog[newChatLog.length - 1] = { speaker: "AI", message: data.ai_response, isLoading: false };
        return newChatLog;
      });
      setChatHistory(prevHistory => {
        let updatedHistory = [...prevHistory];
          updatedHistory.push({
            message: {
              type: "ai",
              data: { content: data.ai_response, example: false },
            },
            timestamp: new Date().getTime()
          });
        return updatedHistory;
      });
    }
    if(data.ai_memories) {
      setMemories(data.ai_memories);
    }
  };

  const handleDoppleClick = (dopple) => {
    setDoppleSearch('');
    setSelectedDoppleDetails(dopple);
    setUploadCompleted(false);
    setChatHistory([]);
    setChatLog([]);
    const greetingMessage = {
      message: {
        type: "ai", 
        data: {
          content: dopple.dopple_greeting, 
          example: false
        }
      },
      timestamp: new Date().getTime()
    };
    setChatHistory(prevHistory => [greetingMessage, ...prevHistory]);
    setChatLog(prevChatLog => [
      { speaker: "AI", message: dopple.dopple_greeting, isLoading: false },
      ...prevChatLog
    ]);
  };

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const uploadDataAsJson = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setDataUpload(true);
    setUploadCompleted(false);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      try {
        const uploadData = JSON.parse(content);
        const url = `https://dopplebrain-dev-kvwjgeyzqa-uc.a.run.app/get_dopple_info`
        const requestBody = {
          dopple_id: uploadData.dopple_id
        }
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer fda1efa9-8fab-49af-8eca-6afd2818dd64'
            },
            body: JSON.stringify(requestBody), 
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          const doppleDetails = {
            doppleImageUrl: data.dopple_info.avatar_url,
            dopple_id: data.dopple_info.id,
            dopple_username: data.dopple_info.dopple_username,
            dopple_name: data.dopple_info.display_name,
            dopple_greeting: data.dopple_info.greeting
          };
          if (uploadData) {
            setTopRules(uploadData.rules.topRules);
            setMidRules(uploadData.rules.midRules);
            setBottomRules(uploadData.rules.bottomRules);
            setCharacterPrompt(uploadData.characterPrompt);
            setShots(uploadData.shots);
            setSelectedModel(uploadData.model || '');
            setChatLog(formatChatHistoryForChatLog(uploadData.chatHistory) || []);
            setChatHistory(uploadData.chatHistory || []);
            setSelectedDoppleDetails(doppleDetails);
          }
          setSuccessMessage('Uploaded Successfully!');
          fetchChatID();
        } catch (error) {
          console.error('Error:', error);
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
      setDataUpload(false);
      setUploadCompleted(true);
    };
    reader.readAsText(file);
  };

  const downloadDataAsJson = async () => {
    const data = {
      rules: {topRules,
      midRules,
      bottomRules
      },
      characterPrompt,
      shots: shots,
      persona: persona,
      dopple_id: selectedDoppleDetails.dopple_id,
      parameters: {
      },
      model: selectedModel,
      chatHistory,
    };

    const requestBody = {
      info_to_save: data
    }

    const url = `https://dopplebrain-dev-kvwjgeyzqa-uc.a.run.app/save_chat_to_gcp_storage`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer fda1efa9-8fab-49af-8eca-6afd2818dd64'
        },
        body: JSON.stringify(requestBody), 
      });
      const responseData = await response.json();
      setSuccessMessage(responseData.message);
    } catch (error) {
      console.error('Error:', error);
    } 
      
    const fileName = selectedDoppleDetails.dopple_name +'_exportedData.json';
    const jsonStr = JSON.stringify(data);
    const blob = new Blob([jsonStr], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClearChat = async () => {
    const greetingMessage = {
      message: {
        type: "ai", 
        data: {
          content: selectedDoppleDetails.dopple_greeting, 
          example: false
        }
      },
      timestamp: new Date().getTime()
    };

    const requestBody = {
      username: 'PROMPT_ENGINEERING_USER',
      dopple_id: selectedDoppleDetails.dopple_id,
      chat_id: chatID
    }
    const url = `https://dopplebrain-dev-kvwjgeyzqa-uc.a.run.app/clear_prompt_engineering_tool_memories`;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer fda1efa9-8fab-49af-8eca-6afd2818dd64'
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      setSuccessMessage(data.message);
    } catch (error) {
      console.error('Error:', error);
    }
    setChatHistory([greetingMessage]);
    setChatLog([{ speaker: "AI", message: selectedDoppleDetails.dopple_greeting, isLoading: false }]);
  };

  const handleReRoll = async () => {
    setRotate(true);
    const requestBody = {
      username: 'PROMPT_ENGINEERING_USER',
      dopple_id: selectedDoppleDetails.dopple_id,
      chat_id: chatID,
      user_query: lastUserQuery,
      stream_mode: 'none',
      images: false,
      folder: '',
    }
    const url = `https://dopplebrain-dev-kvwjgeyzqa-uc.a.run.app/replace_last_ai_response`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer fda1efa9-8fab-49af-8eca-6afd2818dd64'
        },
        body: JSON.stringify(requestBody), 
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const rerollResponse = data.response;

      const lastAiHistoryIndex = [...chatHistory].reverse().findIndex(item => item.message.type === "ai");
      const historyIndex = lastAiHistoryIndex >= 0 ? chatHistory.length - 1 - lastAiHistoryIndex : -1;
      if (historyIndex !== -1) {
        const originalMessage = chatHistory[historyIndex].message.data.content;
        setRerolledMessages(prevRerolledMessages => {
          if (!prevRerolledMessages || prevRerolledMessages.length === 0) {
            return [originalMessage, rerollResponse];
          } else {
            return [...prevRerolledMessages, rerollResponse];
          }
        });
      }
      setRotate(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChange = (e) => {
    setIsChangingMessage(true);
    setUserQueryPrompt(e.target.value);
  };
  
  const handleEditMessageX = (e) => {
    setUserQueryPrompt('');
    setIsEditMessage(false);
  }

  const handleUpdateMessage = async () => {
    if (editingMessageIndex !== null) {
      const updatedChatLog = chatLog.map((conv, index) => {
        if (index === editingMessageIndex) {
          return { ...conv, message: userQueryPrompt };
        }
        return conv;
      });
      setChatLog(updatedChatLog);
  
      setChatHistory(prevHistory => {
        const updatedHistory = [...prevHistory];
        if (updatedHistory[editingMessageIndex] && updatedHistory[editingMessageIndex].message.type === "user") {
          updatedHistory[editingMessageIndex] = {
            ...updatedHistory[editingMessageIndex],
            message: {
              ...updatedHistory[editingMessageIndex].message,
              data: {
                ...updatedHistory[editingMessageIndex].message.data,
                content: userQueryPrompt
              }
            },
            timestamp: new Date().getTime()
          };
        }
        return updatedHistory;
      });
  
      setIsEditMessage(false);
      await fetchAIResponse(userQueryPrompt, true);
      setEditingMessageIndex(null);
    }
  };

  const handleUserMessage = () => {
    if(userQueryPrompt.trim() !== ''){
      setChatLog(prevChatLog => [...prevChatLog, { speaker: "user", message: userQueryPrompt }]);  
    }
    fetchAIResponse(); 
    setUserQueryPrompt('');
  };

  const goToNextMessage = () => {
    setCurrentRerollIndex(prevIndex => Math.min(prevIndex + 1, rerolledMessages.length - 1));
  };

  const goToPreviousMessage = () => {
    setCurrentRerollIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleCheckboxChange = (checked, index) => {
    const newCheckedMessages = { ...checkedMessages };
    if (checked) {
      const nextIndex = index + 1;
      const canSelect =
        nextIndex >= chatLog.length ||
        newCheckedMessages[nextIndex] ||
        chatLog[index].speaker === "user" ||
        chatLog[index - 1].speaker === "AI";
      if (canSelect) {
        if (chatLog[index].speaker === "AI") {
          newCheckedMessages[index] = true;
          for (let i = index + 1; i < chatLog.length; i++) {
            newCheckedMessages[i] = true;
          }
          const prevIndex = index - 1;
          if (prevIndex >= 0 && chatLog[prevIndex].speaker === "user") {
            newCheckedMessages[prevIndex] = true;
          }
        } else {
          newCheckedMessages[index] = true;
          for (let i = index + 1; i < chatLog.length; i++) {
            newCheckedMessages[i] = true;
          }
        }
      }
    } else {
      const isUserMessage = chatLog[index].speaker === "user";
      if (isUserMessage) {
        newCheckedMessages[index] = false;
        for (let i = index + 1; i < chatLog.length && chatLog[i].speaker !== "user"; i++) {
          newCheckedMessages[i] = false;
        }
        for (let i = index - 1; i >= 0; i--) {
          newCheckedMessages[i] = false;
        }
      } else {
        newCheckedMessages[index] = false;
        for (let i = index - 1; i >= 0; i--) {
          newCheckedMessages[i] = false;
        }
      }
    }
    setCheckedMessages(newCheckedMessages);
  };

  const handleDeleteLastMessagePair = async () => {
    let newChatLog = [...chatLog];
    let newChatHistory = [...chatHistory];
    let checkedCount = 0;

    for (let i = newChatLog.length - 1; i >= 0; i--) {
      if (checkedMessages[i]) {
        checkedCount++;
        newChatLog.splice(i, 1);
        newChatHistory.splice(i, 1);
      } else {
        break;
      }
    }
    if (checkedCount > 0) {
      const requestBody = {
        username: 'PROMPT_ENGINEERING_USER',
        dopple_id: selectedDoppleDetails.dopple_id,
        chat_id: chatID,
        folder: '',
        n: checkedCount
      }
      const url = `https://dopplebrain-dev-kvwjgeyzqa-uc.a.run.app/delete_last_n_messages`;
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer fda1efa9-8fab-49af-8eca-6afd2818dd64'
          },
          body: JSON.stringify(requestBody),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error:', error);
      }
      setChatLog(newChatLog);
      setChatHistory(newChatHistory);
    }
    setDeleteMessagesBubbles(false);
  };

  const handleCancelClick = () => {
    setDeleteMessagesBubbles(false);
    setCheckedMessages([]);
  };

  useEffect(() => {
    fetchModels()
  }, [fetchModels])

  useEffect(() => {
    if (selectedDoppleDetails && !dataUpload && !uploadCompleted) {
      setTopRules('');
      setMidRules('');
      setBottomRules('');
      setCharacterPrompt('');
      setShots([]);
      fetchDoppleRules();
      fetchDopplePrompt();
      fetchDoppleShots();
      fetchChatHistory();
    }
    fetchChatID();
  }, [fetchDopplePrompt, fetchDoppleRules, fetchDoppleShots, selectedDoppleDetails, dataUpload, uploadCompleted, fetchChatID, fetchChatHistory]);

  useEffect(() => {
    if (successMessage) {
      setShowAlert(true); 
      setAnimate(false); 
      const showTimer = setTimeout(() => {
        setAnimate(true); 
        const hideTimer = setTimeout(() => {
          setShowAlert(false); 
          setSuccessMessage(null); 
        }, 1000); 
        return () => clearTimeout(hideTimer); 
      }, 4000); 
      return () => clearTimeout(showTimer); 
    }
  }, [successMessage]);

  useEffect(() => {
    setDoppleList([]);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    if (doppleSearch.trim()) {
      const timer = setTimeout(() => {
        handleDoppleFetch();
      }, 400);
      setDebounceTimeout(timer);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setDoppleList([]);
    }
    //eslint-disable-next-line
  }, [doppleSearch]);

  useEffect(() => {
    setLastIndexUserMessage(chatLog.reduce((acc, conv, index) => conv.speaker === "user" ? index : acc, -1))
  }, [chatLog])

  return (
    <div className="h-screen text-bold bg-[#141518] text-white flex space-x-4 justify-center items-center px-10">
      {showAlert && (
        <div className={`fixed top-3 right-3 ${animate ? "animate-fadeOut" : ""} `}>
          <Alert variant="outlined" severity="success">
            {successMessage}
          </Alert>
        </div>
      )}
      <div className="w-1/2 space-y-5 bg-[#191A1E] rounded-2xl h-[96%] p-4 flex flex-col">
        <DoppleSearch 
          doppleSearch={doppleSearch}
          setDoppleSearch={setDoppleSearch}
          doppleList={doppleList}
          handleDoppleClick={handleDoppleClick}
          scrollRef={scrollRef}
          hasScrolled={hasScrolled}
          setHasScrolled={setHasScrolled}
          handleDoppleFetch={handleDoppleFetch}
          isDoppleFetch={isDoppleFetch}
          models={models}
          handleModelSelect={handleModelSelect}
          fetchDopplePrompt={fetchDopplePrompt}
          fetchDoppleRules={fetchDoppleRules}
          tabValue={tabValue}
          selectedDoppleDetails={selectedDoppleDetails}
          rotate={rotate}
        />
        <div className="flex justify-between items-center">
          <CustomTabs onChange={handleTabChange} value={tabValue} />
          <FileMenu
            uploadDataAsJson={uploadDataAsJson}
            downloadDataAsJson={downloadDataAsJson}
            selectedDoppleDetails={selectedDoppleDetails}
          />
        </div>
        {tabValue === "rules" ? (
          <RulesSection
            topRules={topRules}
            setTopRules={setTopRules}
            midRules={midRules}
            setMidRules={setMidRules}
            bottomRules={bottomRules}
            setBottomRules={setBottomRules}
          />
        ) : tabValue === "prompt+shots" ? (
          <PromptShotsSection
            characterPrompt={characterPrompt}
            setCharacterPrompt={setCharacterPrompt}
            shots={shots}
            setShots={setShots}
            selectedDoppleDetails={selectedDoppleDetails}
            blankProfile={blankProfile}
            textareaRef={textareaRef}
            shotsBoxRef={shotsBoxRef}
          />
        ) : (
          <PersonaSection
            persona={persona}
            setPersona={setPersona}
            memories={memories}
          />
        )}
      </div>
      <ChatSection
        selectedDoppleDetails={selectedDoppleDetails}
        handleClearChat={handleClearChat}
        chatLog={chatLog}
        handleReRoll={handleReRoll}
        userQueryPrompt={userQueryPrompt}
        setUserQueryPrompt={setUserQueryPrompt}
        handleChange={handleChange}
        handleEditMessageX={handleEditMessageX}
        handleUpdateMessage={handleUpdateMessage}
        handleUserMessage={handleUserMessage}
        isEditMessage={isEditMessage}
        setIsEditMessage={setIsEditMessage}
        isChangingMessage={isChangingMessage}
        setIsChangingMessage={setIsChangingMessage}
        editingMessageIndex={editingMessageIndex}
        setEditingMessageIndex={setEditingMessageIndex}
        rotate={rotate}
        rerolledMessages={rerolledMessages}
        currentRerollIndex={currentRerollIndex}
        goToNextMessage={goToNextMessage}
        goToPreviousMessage={goToPreviousMessage}
        lastIndexUserMessage={lastIndexUserMessage}
        deleteMessagesBubbles={deleteMessagesBubbles}
        setDeleteMessagesBubbles={setDeleteMessagesBubbles}
        checkedMessages={checkedMessages}
        messageBoxRef={messageBoxRef}
        startEditMessage={startEditMessage}
        handleCheckboxChange={handleCheckboxChange}
        handleDeleteLastMessagePair={handleDeleteLastMessagePair}
        handleCancelClick={handleCancelClick}
      />
    </div>
  );
};

export default PromptEng;
